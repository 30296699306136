/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";
import {
  ShareFlightCardData,
  ShareFlightParamsModel,
} from "../models/ShareFlightModel";

import AirRedirectionInput from "../../../common/models/redirection/AirRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import i18n from "../../../i18n/i18n";
import {
  ShareFlightItineraryShort,
  ShareFlightParamsModelShort,
  ShareFlightSegmentShort,
} from "../models/ShareFlightShortModel";
import {
  getFormattedDuration,
  getSearchDeeplink,
  getformattedDateTime,
} from "./ShareHelper";

// format data to fit AirRedirectionInput model and flight details for adaptive card
let airlineCode: any = [];
let airlineNumber: any = [];
let basisCode: any = [];
let numberOfStops: number;
let totalStopDuration: any = [];
let itineraryFlightNumbers: any = [];
let cityDestinationArray: any = [];
let lastCity: any = "";
let cityNameString: any = "";
let tripDepartureDate: any = "";
let tripReturnDate: any = "";
let tripDate: any = "";
let departureLocation: any = [];
let arrivalLocation: any = [];
let dateTime: any = [];
let numberOfFlights: any = [];

function processSegments(segmentsArray: any) {
  // get the number of flights for each itinerary
  numberOfFlights.push(segmentsArray.length);

  for (let j = 0; j < segmentsArray?.length; j += 1) {
    // build arrays with airline codes, numbers and basis codes for AirRedirectionInput
    airlineCode.push(segmentsArray[j].airlineCode);
    airlineNumber.push(+segmentsArray[j].flightNumber);
    basisCode.push(segmentsArray[j].basicCode);

    // calculate the stop duration between segments and format them according to design
    if (segmentsArray[+j + 1]) {
      const startDate = moment(segmentsArray[+j].arrivalDate);
      const endDate = moment(segmentsArray[+j + 1].departureDate);
      const duration = moment.duration(endDate.diff(startDate));

      totalStopDuration.push(` ${getFormattedDuration(duration)}`);
    }

    // push the flight numbers from each segment to an array
    itineraryFlightNumbers.push(` ${segmentsArray[j].flightNumber}`);

    // get the return date from the last segment of the last itinerary
    tripReturnDate = getformattedDateTime(segmentsArray[j].arrivalDate);
  }
}

// function used to replace all occurrences except the first one; used for multi-city (City to City • City • City)
function replaceAllExceptFirst(str: any, search: any, replace: any) {
  return str
    .split(search)
    .reduce(
      (prev: any, curr: any, i: any) =>
        prev + (i === 1 ? search : replace) + curr
    );
}

function getDestinationString(cityDestinations: any, lastDestination: any) {
  if (cityDestinations.length <= 2 && cityDestinations[0] === lastDestination) {
    // for one way and round trip, we are displaying City to City
    return getDestinationWithToText(cityDestinations);
  }
  // for multi-city with two itineraries, we are displaying "City to City • City"
  return getDestinationTextWithDot(cityDestinations, lastDestination);
}

function getDestinationTextWithDot(
  cityDestinations: any,
  lastDestination: any
) {
  // add the last destination to the destinations array and make it into the "City to City to City" format so it works for one way/round trip/multi-city (adaptive card header)
  cityDestinations.push(lastDestination);
  const string = getDestinationWithToText(cityDestinations);

  // format to look according to the design "City to City • City • City"
  return replaceAllExceptFirst(
    string,
    ` ${i18n.t("shareTrip.adaptiveCard.to")} `,
    " • "
  );
}

function getDestinationWithToText(cityDestinations: any) {
  return cityDestinations
    .toString()
    .split(",")
    .join(` ${i18n.t("shareTrip.adaptiveCard.to")} `);
}

function GetFlightSearchURL(flight: ShareFlightParamsModel) {
  const deeplinkData: AirRedirectionInput = {
    segmentType: RedirectionTypeEnum.FLIGHT,
    stopOnStartPage: false,
    departure: departureLocation,
    arrival: arrivalLocation,
    dateTime: dateTime,
    purpose: flight.tripPurposeId,
    airlineCode: airlineCode,
    airlineNumber: airlineNumber,
    basisCode: basisCode,
    numberOfFlights: numberOfFlights,
    buttonType: "bookFlight",
  };

  return getSearchDeeplink(deeplinkData);
}

export function isValidFlightData(
  data: any
): data is ShareFlightParamsModelShort {
  return (
    data.itineraries != null &&
    data.itineraries[0].originAirportName &&
    data.itineraries[0].departureDateTime &&
    data.itineraries[0].departureLocationCode &&
    data.itineraries[0].destinationAirportName &&
    data.itineraries[0].arrivalDateTime
  );
}

export function getShareFlightCardData(
  flight: ShareFlightParamsModel,
  isPreview: boolean
) {
  // reset variables just in case the function gets called multiple times
  airlineCode = [];
  airlineNumber = [];
  basisCode = [];
  departureLocation = [];
  arrivalLocation = [];
  dateTime = [];
  numberOfFlights = [];
  cityDestinationArray = [];
  cityNameString = "";

  // set the departure date for the whole trip for the adaptive card header
  tripDepartureDate = getformattedDateTime(
    flight.itineraries[0].departureDateTime
  );
  tripDepartureDate = getformattedDateTime(
    flight.itineraries[0].departureDateTime
  );

  // adjusting the JSON to fit the adaptive card structure and include necessary details
  flight?.itineraries.forEach((itinerary) => {
    // get departure and arrival location and departure and arrival dates and set them in the respective arrays
    departureLocation.push(itinerary.departureLocationCode);
    arrivalLocation.push(itinerary.arrivalLocationCode);
    dateTime.push(itinerary.departureDateTime);

    // get destinations for adaptive card header
    cityDestinationArray.push(itinerary.departureCityName);

    // reset arrays for each itinerary
    itineraryFlightNumbers = [];
    totalStopDuration = [];

    // get number of stops for each itinerary
    numberOfStops = +itinerary.segmentsNumber - 1;

    const segments = itinerary.segments;
    if (segments != null) {
      processSegments(segments);
    }

    // display Nonstop or x stop/stops based on the number of stops
    if (numberOfStops !== 0) {
      itinerary.numberOfStops =
        numberOfStops > 1
          ? `${numberOfStops} ${i18n.t("shareTrip.adaptiveCard.stopPlural")}, `
          : `${numberOfStops} ${i18n.t(
              "shareTrip.adaptiveCard.stopSingular"
            )}, `;
    } else {
      itinerary.numberOfStops = i18n.t("shareTrip.adaptiveCard.nonStop");
    }

    // get the name of the last destination for the adaptive card header
    lastCity = itinerary.arrivalCityName;

    // set stop duration and flight numbers for each itinerary
    itinerary.stopDuration = totalStopDuration;
    itinerary.itineraryFlightNumbers = itineraryFlightNumbers;

    // format departure and arrival dates for each itinerary to reflect user's locale
    itinerary.formattedDepartureDateTime = getformattedDateTime(
      itinerary.departureDateTime
    );
    itinerary.formattedArrivalDateTime = getformattedDateTime(
      itinerary.arrivalDateTime
    );

    // format departure and arrival dates for each itinerary to reflect user's locale
    itinerary.formattedDepartureDateTime = getformattedDateTime(
      itinerary.departureDateTime
    );
    itinerary.formattedArrivalDateTime = getformattedDateTime(
      itinerary.arrivalDateTime
    );
  });

  cityNameString = getDestinationString(cityDestinationArray, lastCity);

  // if the departure date and return date are the same OR the trip is one way, display just one date
  if (
    tripDepartureDate === tripReturnDate ||
    flight?.itineraries.length === 1
  ) {
    tripDate = tripDepartureDate;
  } else {
    // else display "departureDate - returnDate"
    tripDate = `${tripDepartureDate} - ${tripReturnDate}`;
  }

  const shareFlightCardData: ShareFlightCardData = {
    cityNameString: cityNameString,
    tripDate: tripDate,
    itineraries: flight.itineraries,
    redirectionURL: GetFlightSearchURL(flight),
    isPreview,
    bookingOptionText: i18n.t("shareTrip.adaptiveCard.bookingOption"),
    bookingDetailsText: i18n.t("shareTrip.adaptiveCard.bookingOptionDetails"),
    bookingAvailabilityText: i18n.t(
      "shareTrip.adaptiveCard.bookingAvailability"
    ),
    bookingButtonText: i18n.t("shareTrip.flightDeepLink"),
  };
  return shareFlightCardData;
}

function mapToShareFlightSegment(seg: ShareFlightSegmentShort): any {
  return {
    flightNumber: seg.fno,
    basicCode: seg.bco,
    airlineCode: seg.aco,
    departureDate: seg.dda,
    arrivalDate: seg.ada,
    stopDuration: seg.sdu ?? "",
    stopAfterLocationCode: seg.slc ?? "",
  };
}

function mapToShareFlightItinerary(it: ShareFlightItineraryShort): any {
  return {
    departureDateTime: it.ddt,
    departureLocationCode: it.dlc,
    departureCityName: it.dcn,
    originAirportName: it.oan,
    totalDuration: it.tdu,
    segmentsNumber: it.snu,
    arrivalDateTime: it.adt,
    arrivalLocationCode: it.alc,
    arrivalCityName: it.acn,
    destinationAirportName: it.dan,
    segments: it.seg.map((seg) => mapToShareFlightSegment(seg)),
  };
}

/**
 * Ensures correct mapping from data shared from Cytric Classic to Cytric Easy.
 * Links shared from Cytric Classic have limitation of 1900 characters, so all keys were shortened to only 3 characters.
 *
 * Example: departureDateTime -> ddt
 * One exception: segmentType key is not shortened.
 *
 * @param data from Cytric Classic
 * @returns The mapped ShareFlightParamsModel or null if an error occurs.
 */
export function mapToShareFlightParamsModel(
  data: ShareFlightParamsModelShort
): ShareFlightParamsModel | null {
  try {
    return {
      segmentType: RedirectionTypeEnum.SHARE_FLIGHT,
      tripPurposeId: data.tpi,
      numOfPassengers: data.nop,
      itineraries: data.its.map((it) => mapToShareFlightItinerary(it)),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in mapToShareFlightParamsModel: ", error);
    return null;
  }
}
