import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  EditorToolbar,
  formats,
  modules,
  QuillEditor,
} from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-events";
import {
  AccessibilityDefinition,
  Button,
  DropdownProps,
  Flex,
  FormDropdown,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router-dom";
import TripDestination from "../../../../common/models/TripDestination";
import { useAppSelector } from "../../../../store/StoreHooks";
import { getDevice } from "../../../../utils/device.utils";
import CloseCollaboratorsConfirmation from "../close-collaborators-confirmation/CloseCollaboratorsConfirmation";
import { DropDownProps } from "../models/CloseCollaboratorsModel";
import Styles from "./CloseCollaboratorsEditorDialog.module.scss";
import notifyCloseCollaborators from "./CloseCollaboratorsEditorService";
import htmlToMarkdown from "./utils/convertHtmlToMarkdown";
import getUsersWithImages from "./utils/getUsersWithImage";

export function CloseCollaboratorsEditorDialog({
  location,
}: Readonly<RouteProps>) {
  const [disabledNotifyButton, setDisabledNotifyButton] = useState(true);
  const [editorValueHtml, setEditorValueHtml] = useState<string>("");
  const [editorValueText, setEditorValueText] = useState<string>("");
  const [closeCollaboratorsList, setCloseCollaboratorsList] = useState<
    Array<DropDownProps>
  >([]);
  const [closeCollaboratorsNotifyListIds, setCloseCollaboratorsNotifyListIds] =
    useState<Array<string>>([]);
  const [closeCollaboratorsNotifyList, setCloseCollaboratorsNotifyList] =
    useState<Array<DropDownProps>>([]);
  const [loadingCollaborators, setLoadingCollaborators] = useState(false);

  const quillRef: any = useRef(null);

  const { t } = useTranslation();

  const notificationSentConfirmation = useAppSelector(
    (state) => state.closeCollaborators.notificationSentConfirmation
  );

  const isMobile = ["mobile", "tablet"].includes(getDevice());

  const dateFormat = (date: string) =>
    DateUtils.getDateFormat(date, i18next.language, DateFormat.DATE_LONG);

  // Construct default notification text
  const constructDefaultText = (data: any) => {
    const { startDate, endDate, city } = data;

    const receiverGreeting = i18next.t(
      "reverseCollaborators.notification.receiverText0"
    );
    const receiverTravelPlan = i18next.t(
      "reverseCollaborators.notification.receiverTravelPlan",
      {
        cityToVisit: `${city}`,
      }
    );
    const receiverTravelDates = i18next.t(
      "reverseCollaborators.notification.receiverText3",
      {
        startDate: `${dateFormat(startDate)}`,
        endDate: `${dateFormat(endDate)}`,
      }
    );
    const receiverCatchUpInfo = i18next.t(
      "reverseCollaborators.notification.receiverText4"
    );
    const receiverOneWayTripDate = i18next.t(
      "reverseCollaborators.notification.receiverOneWayTripDate",
      {
        startDate: `${dateFormat(startDate)}`,
      }
    );

    const receiverDefaultText = `
          <p>${receiverGreeting},</p>
          <br>
          <p>${receiverTravelPlan} ${
            endDate ? receiverTravelDates : receiverOneWayTripDate
          }</p>
          <br>
          <p>${receiverCatchUpInfo}</p>
    `;

    return receiverDefaultText;
  };

  // Parses the URL search parameters to extract and decode the close collaborators information.
  const urlInfo = JSON.parse(
    decodeURIComponent(
      location?.search?.replace("?closeCollaboratorsInfo=", "") as string
    )
  );

  const {
    closeCollaboratorIdsToNotify,
    closeCollaboratorIds,
    destination,
    travelTripId,
    themeName,
  } = urlInfo;

  // Strips HTML tags from a given string.
  const stripHtmlTags = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent ?? "";
  };

  // Calculate the number of characters in the editor’s content, excluding the internal marker at the end of editor (quill.getLength()).
  const numberOfChars = Math.max(stripHtmlTags(editorValueText).length - 1, 0);

  const formatOptions = ["bold", "italic", "link", "orderList", "unOrderList"];

  // accessibility messages
  const getA11ySelectionMessage = {
    onAdd: (item: any) =>
      i18next.t("closeCollaborators.list.a11yToSelect", {
        a11y: `${item.header}`,
      }),
    onRemove: (item: any) =>
      i18next.t("closeCollaborators.list.a11yToRemove", {
        a11y: `${item.header}`,
      }),
  };

  const getNotifyButtonAriaLabel = () => {
    if (disabledNotifyButton) {
      return i18next.t("closeCollaborators.editor.disabledButtonHint");
    }
    return "Notify";
  };
  const customTextBehavior = (): AccessibilityDefinition => ({
    attributes: {
      root: {
        "aria-label": `${numberOfChars} characters ${t(
          "closeCollaborators.editor.of"
        )} 1000`,
        role: "none",
        "aria-live": "polite",
      },
    },
  });
  // accessibility messages

  // Update the state with the new list and IDs of the close collaborators
  const handleChangeOfCloseCollaboratorsList = (
    _event: any,
    data: DropdownProps
  ) => {
    setCloseCollaboratorsNotifyList(data.value as Array<DropDownProps>);
    setCloseCollaboratorsNotifyListIds(
      (data.value as Array<DropDownProps>).map((items: any) => items.key)
    );
  };

  // Trigger the notification to the close collaborators
  const handleDialogSubmit = async () => {
    if (editorValueHtml) {
      setLoadingCollaborators(true);
      await notifyCloseCollaborators({
        checkedCollaboratorIds: closeCollaboratorsNotifyListIds,
        collaborators: closeCollaboratorsList.map((list) => ({
          id: list.key,
          displayName: list.header,
          jobTitle: list.content,
          image: list.image,
          userPrincipalName: list.mail,
          hasBeenNotified: false,
          availability: "",
          activity: "",
        })),
        tripDestination: destination as TripDestination,
        tripId: travelTripId,
        customMessage: htmlToMarkdown(editorValueHtml),
      });
      setLoadingCollaborators(false);
    }
  };

  // Handle dialog close
  const handleDialogClose = (notified: boolean) => {
    const action = notified ? "notified" : "close";
    dialog.url.submit(action);
  };

  useEffect(() => {
    app.initialize();
    const defaultText = constructDefaultText(destination);
    setEditorValueHtml(defaultText);
    setEditorValueText(stripHtmlTags(defaultText));
    setCloseCollaboratorsNotifyListIds(closeCollaboratorIdsToNotify);

    const fetchUsersWithImages = async () => {
      try {
        setLoadingCollaborators(true);
        const users = await getUsersWithImages(closeCollaboratorIds);
        setCloseCollaboratorsList(users);
        const notifiedUserList = users.filter((user) =>
          closeCollaboratorIdsToNotify.includes(user.key)
        );
        setCloseCollaboratorsNotifyList(notifiedUserList);
      } catch (error) {
        setCloseCollaboratorsList([]);
        setCloseCollaboratorsNotifyList([]);
      } finally {
        setLoadingCollaborators(false);
      }
    };
    fetchUsersWithImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editorValueHtml) {
      if (
        stripHtmlTags(editorValueHtml).length === 0 ||
        closeCollaboratorsNotifyList.length === 0
      ) {
        setDisabledNotifyButton(true);
      } else {
        setDisabledNotifyButton(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorValueHtml, closeCollaboratorsNotifyList]);

  const labelId = "customize-close-collaborators";

  return (
    <Flex
      column
      className={Styles.textEditor}
      data-testid="close-collaborators-custom-text-dialog"
      styles={(theme) => ({
        backgroundColor:
          theme.theme?.siteVariables?.colorScheme?.default?.background,
      })}
    >
      {notificationSentConfirmation.isVisible ? (
        <CloseCollaboratorsConfirmation
          themeName={themeName}
          fetchCloseCollaborators={{}}
          fromDialog
        />
      ) : (
        <div>
          {loadingCollaborators ? (
            <div className={Styles.loading}>
              <Loader label={i18next.t("closeCollaborators.editor.loading")} />
            </div>
          ) : (
            <div className={`${isMobile ? "mobile" : ""}`}>
              <div className={Styles.container}>
                <FormDropdown
                  label={{
                    content: i18next.t(
                      "closeCollaborators.editor.selectCloseCollaborators"
                    ),
                    id: labelId,
                  }}
                  toggleIndicator=""
                  multiple
                  search
                  fluid
                  placeholder={i18next.t(
                    "closeCollaborators.editor.placeholder"
                  )}
                  a11ySelectedItemsMessage={i18next.t(
                    "closeCollaborators.list.a11ySelected"
                  )}
                  items={closeCollaboratorsList.map((item) => ({
                    key: item.key,
                    image: item.image,
                    header: item.header,
                    content: item.content,
                  }))}
                  getA11ySelectionMessage={getA11ySelectionMessage}
                  noResultsMessage={i18next.t(
                    "closeCollaborators.editor.noResultsMessage"
                  )}
                  onChange={handleChangeOfCloseCollaboratorsList}
                  value={closeCollaboratorsNotifyList}
                  data-testid="close-collaborators-custom-text-dialog-dropdown"
                />
                <Text className={Styles.messageEditor}>
                  {t("closeCollaborators.editor.customMessage")}
                </Text>
                <div>
                  {isMobile ? (
                    <div id="toolbar" aria-hidden className={Styles.toolbar} />
                  ) : (
                    <EditorToolbar config={formatOptions} />
                  )}

                  <Flex
                    column
                    styles={(theme) => ({
                      backgroundColor: isMobile
                        ? "transparent"
                        : theme.theme?.siteVariables?.colorScheme?.default
                            ?.background2,
                      color:
                        theme.theme?.siteVariables?.colorScheme?.default
                          ?.foreground2,
                    })}
                  >
                    <span hidden>
                      {t("closeCollaborators.editor.characterLimit")}
                    </span>
                    <QuillEditor
                      ref={quillRef}
                      placeholder={t(
                        "closeCollaborators.editor.editorPlaceholder"
                      )}
                      modules={modules}
                      formats={formats}
                      defaultValue={editorValueHtml}
                      setEditorValueHtml={setEditorValueHtml}
                      setEditorValueText={setEditorValueText}
                      data={editorValueHtml}
                    />
                  </Flex>
                </div>
                <div className={Styles.numberOfCharacters}>
                  <p className={Styles.screenReaderText}>
                    {`${numberOfChars} ${t(
                      "closeCollaborators.editor.of"
                    )} 1000 ${t("closeCollaborators.editor.characters")}`}
                  </p>
                  <Text
                    content={`${numberOfChars}/1000`}
                    accessibility={customTextBehavior}
                    data-testid="close-collaborators-custom-text-dialog-characters"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <Flex
        hAlign="end"
        className={Styles.buttons}
        styles={(theme) => ({
          borderTop: isMobile ? "none" : "1px solid",
          borderColor:
            theme.theme?.siteVariables?.colorScheme?.default?.border2,
        })}
      >
        {notificationSentConfirmation.isVisible ? (
          <Button
            content={t("closeCollaborators.editor.closeButton")}
            primary
            flat
            onClick={() => handleDialogClose(true)}
            data-testid="close-collaborators-dialog-cancel-button"
            tinted={isMobile}
          />
        ) : (
          <Button
            content={t("closeCollaborators.editor.closeButton")}
            flat
            onClick={() => handleDialogClose(false)}
            data-testid="close-collaborators-dialog-cancel-button"
            tinted={isMobile}
          />
        )}

        {!notificationSentConfirmation.isVisible && (
          <Button
            content={t("closeCollaborators.footer.notifyButton", {
              ccNumber: closeCollaboratorsNotifyList.length,
            })}
            flat
            primary
            onClick={handleDialogSubmit}
            disabled={disabledNotifyButton}
            aria-label={getNotifyButtonAriaLabel()}
            data-testid="close-collaborators-dialog-notify-button"
          />
        )}
      </Flex>
    </Flex>
  );
}

export default CloseCollaboratorsEditorDialog;
