/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DateFormat,
  DateUtils as DateUtilsLibrary,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { IHotelSegmentV1 } from "../../../components/upcoming-trips-dashboard/UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Hotel/Hotel.model";
import {
  PROVIDER_FALLBACK_LOGO,
  UPCOMING_TRIPS,
} from "../../../components/upcoming-trips-dashboard/data/constants";
import { validateProviderLogoUrl } from "../../../components/upcoming-trips-dashboard/utils/trip-utils";
import i18n from "../../../i18n/i18n";
import DateUtils from "../../../utils/date.utils";

const DELIMITER = UPCOMING_TRIPS.delimiterForDestinations;

export const getNumberOfNights = (
  checkInDate: string,
  checkOutDate: string
) => {
  const checkIn: number = DateUtils.getDateInMiliseconds(checkInDate);
  const checkOut: number = DateUtils.getDateInMiliseconds(checkOutDate);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(checkIn) || isNaN(checkOut)) {
    return 0;
  }

  const differenceInTime: number = checkOut - checkIn;

  return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
};

export const getHotelImage = (
  hotelImage: { description: string; href: string; category: string }[]
) => {
  const image = hotelImage.filter(
    (img: { description: string; category: string }) =>
      img.description === "Thumbnail" || img.category === "CHAIN_LOGO"
  );

  return validateProviderLogoUrl(image?.[0]?.href);
};

export const getHotelDetails = (hotel: IHotelSegmentV1) => ({
  name: hotel?.name,
  address: hotel?.address?.lines?.join(` ${DELIMITER} `),
  postalCode: hotel?.address?.postalCode,
  city: hotel?.address?.cityName,
  country: hotel?.address?.countryCode,
  checkInDate: hotel?.checkInDate,
  checkOutDate: hotel?.checkOutDate,
  policiesDescription: hotel?.policies?.cancellation?.description,
  ratingNumber: hotel?.rating,
  numberOfNights: getNumberOfNights(hotel?.checkInDate, hotel?.checkOutDate),
  hotelPicture: getHotelImage(hotel?.media) || PROVIDER_FALLBACK_LOGO,
  phone: hotel?.contact?.phone,
  email: hotel?.contact?.email,
  confirmationNumber: hotel?.tripReference?.providerConfirmationNumber,
  bookingCode: hotel?.roomBookingCode,
});
