/* eslint-disable import/no-cycle */
/* eslint-disable no-debugger */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import i18next from "i18next";
import lodash from "lodash";
import { useEffect, useState } from "react";
import Trip from "../../common/models/Trip";
import TripHelper from "../../helpers/TripHelper";
import TripListService from "../../services/trip-list/TripListService";
import Store from "../../store/Store";
import { useAppSelector } from "../../store/StoreHooks";
import { contextSelector } from "../../store/app-settings/AppSettingsSelector";
import { isLoggedInSelector } from "../../store/authentication/AuthenticationSelector";
import { getSelectedTripId } from "../../store/segment-selected/SegmentSelectedSelector";
import { fetchUserTrips } from "../../store/trips/TripsActions";
import {
  selectIsEmptyTripList,
  selectIsPending,
  selectTripListIsError,
  selectTrips,
} from "../../store/trips/TripsSelector";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import GetGraphAppCatalogId from "../../utils/GetGraphAppCatalogId";
import {
  CP_FLOW_IDENTIFIER,
  FeatureToggleDefinition,
} from "../../utils/constants";
import { getDevice } from "../../utils/device.utils";
import HostSettings from "../../utils/host.settings";
import { ITripV1 } from "../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import ShareJoinStyles from "./ShareJoin.module.scss";
import AdaptiveCardViewer from "./components/share-join-content/adaptive-card-viewer/AdaptiveCardViewer";
import ShareJoinFooter from "./components/share-join-content/share-join-footer/ShareJoinFooter";
import ShareJoinForm from "./components/share-join-content/share-join-form/ShareJoinForm";
import { useStore } from "./context/ShareJoinContext";
import { GetCardToShare } from "./functions/GetCard";
import GetData from "./functions/GetData";
import SubmitTaskModule from "./functions/SubmitEvents";
import { DeeplinkOptions } from "./functions/ac-utils/DeeplinkOptions";
import CheckFeatureMMT from "./functions/ac-utils/checkFeatureMMT";
import getSegmentsData from "./functions/getSegmentsData";
import getTripToShare from "./functions/getTripToShare";
import getError from "./utils/getError";
import trackTelemetry from "./utils/trackTelemetry";
import Welcome from "./utils/welcome/Welcome";

function ShareJoin({ propValue }: any) {
  // REDUX VARIABLES
  const storedTrips: Trip[] = useAppSelector((state) => selectTrips(state));
  const tripId = useAppSelector((state) => getSelectedTripId(state));
  const isPending = useAppSelector((state) => selectIsPending(state));
  const tripListIsError: boolean = useAppSelector((state) =>
    selectTripListIsError(state)
  );
  const isLoggedIn: boolean = useAppSelector((state: any) =>
    isLoggedInSelector(state)
  );
  const isEmptyTripList = useAppSelector((state) =>
    selectIsEmptyTripList(state)
  );

  // GENERAL HOOKS
  const [trips, setTrips] = useState<Trip[] | ITripV1[]>();
  const [disableShare, setDisableShare] = useState(true);
  const [loadingShare, setLoadingShare] = useState(false);
  const [cardToShare, setCardToShare] = useState("");
  const [cardToShareChannels, setCardToShareChannels] = useState("");
  const [appCatalogId, setAppCatalogId] = useState("");
  const [themeName, setThemeName] = useState<any>();
  const [device, setDevice] = useState<any>();
  const [postOnChannel, setPostOnChannel] = useState<boolean>(false);
  const context = useAppSelector((state) => contextSelector(state));
  const mmtActivated = CheckFeatureMMT();
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPAppCatalogId.id
  );
  // Confirmation Page trip retrieval
  const [isTripsRetrievalPending, setIsTripsRetrievalPending] = useState(false);
  const [tripRetrievalError, setTripRetrievalError] = useState(false);
  const [cbpTrips, setCBPTrips] = useState<ITripV1[]>();
  const [clientType, setClientType] = useState<string>("");

  const isFeatureBookSameTrainEnable = CheckFeatureToggle(
    FeatureToggleDefinition.BookSameTrain.id
  );

  const featureRedirectionSameCar = CheckFeatureToggle(
    FeatureToggleDefinition.redirectionSameCar.id
  );

  // SHARING FROM DASHBOARD HOOKS
  const [sharingFromDashboard, setSharingFromDashboard] = useState(false);
  const [origin, setOrigin] = useState("");
  const [shareWithSelected, setShareWithSelected] = useState(false);
  const [recipientSelected, setRecipientSeleted] = useState<string | undefined>(
    ""
  );
  const [recipientGroupsSelected, setRecipientGroupsSelected] = useState<
    any | undefined
  >([]);
  const [recipientChannelsSelected, setRecipientChannelsSelected] =
    useState<any>([]);
  const [tripToShare, setTripToShare] = useState<any>(null);
  const [messageToShare, setMessageToShare] = useState<string>("");

  // SIMPLE VARIABLES
  const classTabletDevice = device === "tablet" ? ShareJoinStyles.tablet : null;
  const { t, language } = i18next;
  const error = getError();
  const {
    tripTemplate,
    segmentsToShare,
    hasAir,
    hasHotel,
    hasRail,
    hasCar,
    initialSegments,
  } = useStore();
  const productsToShare = {
    hasAir,
    hasCar,
    hasHotel,
    hasRail,
    products: segmentsToShare,
  };

  // FUNCTIONS
  const handleClick = async () => {
    if (tripToShare) {
      trackTelemetry(
        tripToShare,
        tripTemplate,
        recipientSelected,
        recipientGroupsSelected,
        recipientChannelsSelected,
        origin,
        initialSegments
      );

      setDisableShare(true);
      setLoadingShare(true);
      const tripName = tripToShare.name;
      const tripToShareId = tripToShare.id;
      const segmentsData = getSegmentsData(segmentsToShare);
      const getDataProps = {
        recipientSelected,
        messageToShare,
        cardToShare,
        cardToShareChannels,
        tripName,
        tripToShareId,
        recipientGroupsSelected,
        recipientChannelsSelected,
        appCatalogId,
        segmentsData,
        origin,
        postOnChannel,
        device,
        t,
      };
      const datafinal = await GetData(getDataProps);
      sessionStorage.setItem("try_again_attempts", "0");

      if (cardToShare) {
        telemetryService.trackTrace(
          { message: "travel-mmt-appcatalogid" },
          { appCatalogId }
        );
        SubmitTaskModule(datafinal);
      }
    }
  };

  const closeTask = (): void => {
    // workaround for events when we need to reload page
    if (origin === "events") {
      dialog.url.submit("close");
    } else {
      dialog.url.submit();
    }
  };

  // useEffect'S
  useEffect(() => {
    Store.dispatch(fetchUserTrips());
    if (!sharingFromDashboard) {
      if (isLoggedIn) {
        telemetryService.trackEvent({
          name: "chat-extension-open",
        });
      }
    } else {
      telemetryService.trackEvent({
        name: "travel-tripDetails-sharePopup",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  useEffect(() => {
    if (
      (recipientSelected && recipientSelected.length > 0) ||
      (recipientGroupsSelected && recipientGroupsSelected.length > 0) ||
      (recipientChannelsSelected && recipientChannelsSelected.length > 0)
    ) {
      setShareWithSelected(true);
    } else {
      setShareWithSelected(false);
    }
  }, [
    recipientChannelsSelected,
    recipientGroupsSelected,
    recipientSelected,
    shareWithSelected,
  ]);

  useEffect(() => {
    if (segmentsToShare) {
      setTripToShare(getTripToShare(tripTemplate, productsToShare));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentsToShare]);

  useEffect(() => {
    if (propValue?.location?.search.includes("origin=notification")) {
      const paramTripId = propValue.location.search
        ?.split("&")[1]
        ?.split("=")[1];
      telemetryService.trackEvent(
        { name: "travel-notification-sharePopup" },
        { tripId: paramTripId ?? "" }
      );
    }
  }, [propValue?.location?.search]);

  useEffect(() => {
    const deeplinkOptions: DeeplinkOptions = {
      isTRIPPUser,
      mmtActivated,
      isFeatureBookSameTrainEnable,
      featureRedirectionSameCar,
    };
    if (tripToShare) {
      const { general, forChannels } = GetCardToShare(
        tripToShare,
        appCatalogId,
        recipientChannelsSelected,
        origin,
        language,
        clientType,
        deeplinkOptions
      );
      setCardToShare(general);
      if (recipientChannelsSelected && origin !== "events") {
        setCardToShareChannels(forChannels);
      }
    }
  }, [
    tripToShare,
    appCatalogId,
    recipientChannelsSelected,
    origin,
    mmtActivated,
    isTRIPPUser,
    isFeatureBookSameTrainEnable,
    featureRedirectionSameCar,
    language,
    clientType,
  ]);

  const getDestinationPicture = (trip: Trip) => {
    let destinationPicture;
    if (trip.destinationPicture && trip.destinationPicture.length === 0) {
      const imageSource = HostSettings.getCDNImages;
      destinationPicture = [
        `${imageSource}/default/slider.png`,
        `${imageSource}default/square.png`,
        `${imageSource}default/largeRectangle.png`,
      ];
      return destinationPicture;
    }
    return trip.destinationPicture;
  };

  const getCBPTrips = async (cbpTripId: string) => {
    try {
      const res = await TripListService.getTripByIdV1Arranger(cbpTripId);
      setIsTripsRetrievalPending(false);
      return res.data;
    } catch (apiError) {
      setTripRetrievalError(true);
      setIsTripsRetrievalPending(false);
      return [];
    }
  };

  useEffect(() => {
    if (cbpTrips) {
      setSharingFromDashboard(false);
      setTrips(cbpTrips);
      setOrigin(CP_FLOW_IDENTIFIER);
    }
  }, [cbpTrips]);

  useEffect(() => {
    let tripsFormatedWithBounds: Trip[] | ITripV1[] = [];
    if (propValue?.location?.search.includes(CP_FLOW_IDENTIFIER)) {
      setIsTripsRetrievalPending(true);
      const tripIdCBP = propValue?.location?.search.split("&")[1].split("=")[1];
      getCBPTrips(tripIdCBP).then((data) => {
        tripsFormatedWithBounds = [data] as unknown as ITripV1[];
        setCBPTrips(tripsFormatedWithBounds);
      });
    } else {
      const copyOfTrips = lodash.cloneDeep(storedTrips);
      tripsFormatedWithBounds = copyOfTrips.map((boundtrip: Trip) => {
        const tripFormatted = {
          ...boundtrip,
          destinationPicture: getDestinationPicture(boundtrip),
          products: TripHelper.formatTripProductsWithBound(
            boundtrip.products || []
          ),
        };
        const translations = {
          tripTo: t(`shareTrip.adaptiveCard.tripTitle.tripTo`),
          tripByCar: t(`shareTrip.adaptiveCard.tripTitle.tripByCar`),
          tripString: t(`shareTrip.adaptiveCard.tripTitle.trip`),
        };

        tripFormatted.name = TripHelper.computeTripTitle(
          tripFormatted,
          translations
        );
        return tripFormatted;
      });
      if (propValue?.location?.search.includes("tripId")) {
        const paramTripId = propValue.location.search
          .split("&")[1]
          .split("=")[1];
        const originProp = propValue.location.search
          .split("&")[0]
          .split("=")[1];
        const tripsFormatedWithBoundsCasted = tripsFormatedWithBounds as Trip[];
        const paramTrip: any = tripsFormatedWithBoundsCasted.filter(
          (element) => element.id === paramTripId
        );
        setTrips(paramTrip);
        setSharingFromDashboard(true);
        setOrigin(originProp);
      } else {
        const originProp = propValue?.location?.search.split("=")[1];
        setSharingFromDashboard(false);
        setTrips(tripsFormatedWithBounds);
        setOrigin(originProp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedTrips, propValue, t]);

  useEffect(() => {
    if (origin === "events" && context) {
      const { channel, team, chat } = context;
      const eventId = chat ? chat.id : channel?.id;
      const teamId = chat ? "groupChat" : team?.groupId;
      if (chat) {
        setRecipientGroupsSelected([eventId]);
      } else {
        setRecipientChannelsSelected([{ channelId: eventId, teamId }]);
      }
    }
  }, [context, origin]);

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        setThemeName(ctx?.app.theme);
        setClientType(ctx?.app.host.clientType);
      });
    });

    setDevice(getDevice());
  }, []);

  useEffect(() => {
    GetGraphAppCatalogId(setAppCatalogId, isTRIPPUser);
  }, [isTRIPPUser]);

  const getComponentBackground = (theme: any) => {
    if (device === "mobile" || device === "tablet") {
      return theme.theme.siteVariables.colorScheme?.default.background3;
    }
    if (
      sharingFromDashboard ||
      origin === "events" ||
      origin === CP_FLOW_IDENTIFIER
    ) {
      return theme.theme.siteVariables.colorScheme?.default.background;
    }
    return theme.theme.siteVariables.colorScheme?.default.background6;
  };

  return (
    <Flex
      column
      data-testid="share-join"
      className={ShareJoinStyles.shareJoinContainer}
    >
      <Flex
        column
        gap="gap.smaller"
        className={[
          themeName === "default"
            ? ShareJoinStyles.white
            : ShareJoinStyles.dark,
          sharingFromDashboard
            ? ShareJoinStyles.shareContent
            : ShareJoinStyles.shareContentMessaging,
          classTabletDevice,
          origin === "events" && ShareJoinStyles.eventsShareContentMessaging,
        ].join(" ")}
        styles={(theme) => ({
          backgroundColor: getComponentBackground(theme),
        })}
      >
        {((isPending && origin !== CP_FLOW_IDENTIFIER) ||
          isTripsRetrievalPending) && (
          <Loader
            size="medium"
            className="trip-loader"
            label={t(`shareTrip.loaderLabel`)}
            aria-label={t(`shareTrip.loaderLabel`)}
          />
        )}
        {(tripListIsError || tripRetrievalError) && error}
        {isEmptyTripList &&
        !sharingFromDashboard &&
        origin !== CP_FLOW_IDENTIFIER ? (
          <Welcome />
        ) : (
          <>
            {(sharingFromDashboard || origin === CP_FLOW_IDENTIFIER) && (
              <ShareJoinForm
                setRecipientSeleted={setRecipientSeleted}
                setDisableShare={setDisableShare}
                setRecipientGroupsSelected={setRecipientGroupsSelected}
                setRecipientChannelsSelected={setRecipientChannelsSelected}
                setMessageToShare={setMessageToShare}
                device={device}
                themeName={themeName}
              />
            )}

            {origin === "events" && (
              <Text size="medium" className={ShareJoinStyles.eventsTopMessage}>
                {t(`events.taskModuleEvents.topMessagge`)}
              </Text>
            )}

            {trips && (
              <AdaptiveCardViewer
                trips={trips}
                tripList={!sharingFromDashboard}
                origin={origin}
              />
            )}
          </>
        )}
      </Flex>
      <ShareJoinFooter
        handleClick={handleClick}
        closeTask={closeTask}
        themeName={themeName}
        disableShare={disableShare}
        setDisableShare={setDisableShare}
        shareWithSelected={shareWithSelected}
        sharingFromDashboard={sharingFromDashboard}
        loadingShare={loadingShare}
        device={device}
        classTabletDevice={classTabletDevice}
        segments={segmentsToShare}
        origin={origin}
        postOnChannel={postOnChannel}
        setPostOnChannel={setPostOnChannel}
        context={context}
      />
    </Flex>
  );
}

export default ShareJoin;
