/* eslint-disable no-template-curly-in-string */
const AuditorCanChatWithTravelerCard = {
  $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
  type: "AdaptiveCard",
  version: "1.3",
  body: [
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "40px",
              items: [
                {
                  type: "Image",
                  url: "https://cdn-msnt-cyt-tst-cdn-01.azureedge.net/icons/blue-folder-transparent.png",
                  altText: "${texts.folderIconAltText}",
                },
              ],
            },
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "ColumnSet",
                  spacing: "None",
                  columns: [
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${nameOfES}",
                          wrap: true,
                          weight: "Bolder",
                          color: "Default",
                          size: "Large",
                          maxLines: 1,
                          spacing: "Small",
                        },
                      ],
                    },
                    {
                      type: "Column",
                      width: "auto",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${texts.status}",
                          spacing: "None",
                          size: "Small",
                          weight: "Lighter",
                          isSubtle: true,
                        },
                      ],
                      horizontalAlignment: "Right",
                      verticalContentAlignment: "Center",
                    },
                  ],
                },
                {
                  type: "TextBlock",
                  text: "${creationDate}",
                  wrap: true,
                  weight: "Lighter",
                  spacing: "Small",
                  isSubtle: true,
                  size: "Medium",
                },
                {
                  type: "TextBlock",
                  text: "${description}",
                  wrap: true,
                  spacing: "Small",
                  size: "Small",
                },
              ],
              spacing: "Default",
            },
          ],
          spacing: "None",
        },
      ],
      separator: true,
      spacing: "Medium",
    },
    {
      type: "Container",
      items: [],
      spacing: "None",
      horizontalAlignment: "Center",
      minHeight: "12px",
    },
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${texts.allReceipts}",
                  wrap: true,
                  spacing: "None",
                  weight: "Default",
                  size: "Small",
                },
              ],
              spacing: "None",
              horizontalAlignment: "Left",
            },
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${totalAmount} ${currencyCode}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Small",
                },
              ],
              horizontalAlignment: "Right",
            },
          ],
          spacing: "None",
          horizontalAlignment: "Center",
        },
      ],
      separator: true,
      spacing: "Medium",
    },
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "TextBlock",
                  text: "${texts.nonReimbursable}",
                  wrap: true,
                  size: "Small",
                },
              ],
              spacing: "None",
              horizontalAlignment: "Left",
            },
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${nonReimbursableAmount} ${currencyCode}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Small",
                },
              ],
              spacing: "None",
              horizontalAlignment: "Right",
            },
          ],
          spacing: "None",
        },
      ],
      spacing: "Medium",
      separator: true,
    },
    {
      type: "Container",
      items: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "auto",
                      items: [
                        {
                          type: "Image",
                          spacing: "None",
                          width: "20px",
                          height: "20px",
                          url: "https://cdn-msnt-cyt-tst-cdn-01.azureedge.net/icons/checked-icon-grey.png",
                          horizontalAlignment: "Left",
                          altText: "${texts.checkedReimbursementIconAltText}",
                        },
                      ],
                      spacing: "None",
                      horizontalAlignment: "Left",
                      verticalContentAlignment: "Center",
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${texts.totalReimbursement}",
                          wrap: true,
                          weight: "Bolder",
                          size: "Small",
                        },
                      ],
                      spacing: "Default",
                      horizontalAlignment: "Left",
                      verticalContentAlignment: "Center",
                    },
                  ],
                  spacing: "None",
                },
              ],
            },
            {
              type: "Column",
              width: "auto",
              items: [
                {
                  type: "TextBlock",
                  text: "${reimbursementAmount} ${currencyCode}",
                  wrap: true,
                  weight: "Bolder",
                  size: "Small",
                  spacing: "None",
                },
              ],
              spacing: "None",
              horizontalAlignment: "Right",
            },
          ],
        },
      ],
      spacing: "Medium",
      separator: true,
    },
  ],
};

export default AuditorCanChatWithTravelerCard;
