/* eslint-disable import/prefer-default-export */

/**
 * Decodes the given subEntityId even if it contains special characters.
 *
 * Note: This is a workaround for unicode issue:
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 *
 * @param subEntityId - The subEntityId to decode.
 * @returns The decoded subEntityId.
 */
export function decodeEntity(subEntityId: string) {
  const binaryString = atob(subEntityId);
  const characterArray = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
  return new TextDecoder().decode(characterArray);
}

/**
 * Decodes the given subEntityId if it is shared from Cytric Classic.
 * All urls coming from Cytric Classic are encoded in base64.
 *
 * @param subEntityId - The subEntityId to decode.
 * @returns The decoded subEntityId.
 */
export function decodeIfSharedFromCytric(
  subEntityId: string | undefined
): string {
  if (subEntityId == null) {
    return "";
  }
  try {
    return subEntityId ? decodeEntity(subEntityId) : subEntityId;
  } catch {
    // If subEntityId is not encoded in base64, return it as is.
    // It is most likely a link from other Cytric Easy feature, not from Cytric Classic.
    return subEntityId;
  }
}

/**
 * Gets formatted duration of stop based on given time duration data.
 *
 * @param time stop duration in format 7:05
 * @returns stop duration in format ` 07h 05m`
 */
export function getFormattedStopDuration(time: string) {
  if (time.split(":").length !== 2) {
    return " ";
  }
  const [hours, minutes] = time.split(":").map((unit) => unit.padStart(2, "0"));
  return ` ${hours}h ${minutes}m`;
}

/**
 * Gets remapped itinerary with additional stop data - stopDuration, numberOfStops, stopAfterLocationCode.
 * If the segment is the last one or there is only one segment, the additional data are empty.
 *
 * @param itinerary itinerary data
 * @returns remapped itinerary data
 */
export function getRemappedItineraries(itinerary: any) {
  const itineraryResult = { ...itinerary };
  if (itinerary.segments.length > 0) {
    const emptyStopDuration = "";
    const emptyStopAfterLocationCode = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itinerary.segments.length; i++) {
      if (i === itinerary.segments.length - 1) {
        itineraryResult.segments[i] = {
          ...itinerary.segments[i],
          stopDuration: [emptyStopDuration],
          numberOfStops: itinerary.segments.length - 1,
          stopAfterLocationCode: emptyStopAfterLocationCode,
        };
      } else {
        itineraryResult.segments[i] = {
          ...itinerary.segments[i],
          stopDuration: [
            getFormattedStopDuration(itinerary.segments[i].stopDuration),
          ],
          numberOfStops: itinerary.segments.length - 1,
          stopAfterLocationCode: itinerary.segments[i].stopAfterLocationCode,
        };
      }
    }
  }
  return itineraryResult;
}
