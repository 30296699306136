import { Flex, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Styles from "../../ShareMyRide.module.scss";

function LoadingSpinner({ themeName }: any) {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        className={[
          themeName === "light" ? Styles.whiteOverlay : Styles.darkOverlay,
          Styles.overlay,
        ].join(" ")}
      />
      <Loader
        className={Styles.spinner}
        aria-label={t("spinner.loaderLabel")}
      />
    </>
  );
}

export default LoadingSpinner;
