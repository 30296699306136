import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";

export async function getBatchChannels(teamsInfo: any) {
  return teamsInfo.map((item: any) => ({
    id: item.id,
    request: new Request(
      `teams/${item.id}/channels?$filter=not(membershipType eq 'private')`,
      {
        method: "GET",
      }
    ),
  }));
}

export default async function getBatchChannelsGraphCall(
  teamsInfo: any,
  graph: any
) {
  const batchLimit = 20;
  const graphChannels = [];

  for (let i = 0; i < teamsInfo.length; i += batchLimit) {
    const start = i;
    const end = i + batchLimit;

    const teamsInfoFractions = teamsInfo.slice(start, end);

    const batchRequestContent = new MicrosoftGraph.BatchRequestContent(
      // eslint-disable-next-line no-await-in-loop
      await getBatchChannels(teamsInfoFractions)
    );

    // eslint-disable-next-line no-await-in-loop
    const content = await batchRequestContent.getContent();
    // eslint-disable-next-line no-await-in-loop
    const batchResponses = await graph.api("/$batch").post(content);

    graphChannels.push(...batchResponses.responses);
  }

  return graphChannels;
}
