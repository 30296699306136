import moment from "moment";
import i18n from "../../../i18n/i18n";
import {
  IAuditorCanChatWithTraveler,
  IAuditorCanChatWithTravelerCardData,
} from "../models/AuditorModel";

export function getAuditorTravelerCardData(data: any, isPreview = false) {
  const dateFormat = "DD.MM.YYYY";
  const creationDate = moment(data.creationDateES).format(dateFormat);
  const valueWithDecimals = (value: any) => Number(value).toFixed(2);

  const auditorChatWithTravelerData: IAuditorCanChatWithTravelerCardData = {
    nameOfES: data.nameES,
    creationDate,
    description: data.descriptionES,
    totalAmount: valueWithDecimals(data.totalAmount),
    nonReimbursableAmount: valueWithDecimals(data.nonReimbursableAmount),
    reimbursementAmount: valueWithDecimals(data.reimbursementAmount),
    currencyCode: data.currencyCode,
    texts: {
      status: i18n.t("chatWithTraveler.adaptiveCard.status.auditing"),
      allReceipts: i18n.t(
        "chatWithTraveler.adaptiveCard.allReceiptsAndItineraries"
      ),
      nonReimbursable: i18n.t(
        "chatWithTraveler.adaptiveCard.nonReimbursableAmount"
      ),
      totalReimbursement: i18n.t(
        "chatWithTraveler.adaptiveCard.totalReimbursement"
      ),
      buttonReview: i18n.t("chatWithTraveler.adaptiveCard.buttonReview"),
      folderIconAltText: i18n.t(
        "chatWithTraveler.accessibility.icon.folderIcon"
      ),
      checkedReimbursementIconAltText: i18n.t(
        "chatWithTraveler.accessibility.icon.reimbursementIcon"
      ),
    },
    isPreview,
  };

  return auditorChatWithTravelerData;
}

export function isValidAuditorTravelerData(
  data: any
): data is IAuditorCanChatWithTraveler {
  return (
    data.segmentType !== undefined &&
    data.firstName !== undefined &&
    data.lastName !== undefined &&
    data.emailAddress !== undefined &&
    data.cytricID !== undefined &&
    data.nameES !== undefined &&
    data.idES !== undefined &&
    data.creationDateES !== undefined &&
    data.descriptionES !== undefined &&
    data.totalAmount !== undefined &&
    data.nonReimbursableAmount !== undefined &&
    data.reimbursementAmount !== undefined &&
    data.currencyCode !== undefined
  );
}
