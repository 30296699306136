/* eslint-disable react/destructuring-assignment */
import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import { useEffect } from "react";
import ProductTrain from "../../../../../common/models/ProductTrain";
import TripHelperV1 from "../../../../../helpers/TripHelperV1";
import {
  IArrivalDepartureRail,
  IProductV1,
  IVehicleRail,
} from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { useDispatch } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import SegmentWrapper from "../segments/SegmentWrapper";

interface RailSegmentContentProps {
  railSegment: IProductV1[];
  id: number;
}

function RailSegmentContentV1({ railSegment, id }: RailSegmentContentProps) {
  const dispatch = useDispatch();
  const firstSegment = railSegment[0];
  const lastSegment = railSegment[railSegment.length - 1];
  const vehicle = railSegment[0].vehicle as IVehicleRail;
  const departure = firstSegment.departure as IArrivalDepartureRail;
  const arrival = lastSegment.arrival as IArrivalDepartureRail;
  const departureDateTime = railSegment[0].departureDateTime ?? "";
  const arrivalDateTime =
    railSegment[railSegment.length - 1].arrivalDateTime ?? "";
  const { range } = TripHelperV1.getStartEndDate(
    departureDateTime,
    arrivalDateTime
  );
  const numOfStops = TripHelperV1.getNumOfStopsText(railSegment.length - 1);
  const travelDuration = TripHelperV1.getTravelDuration(
    departureDateTime,
    arrivalDateTime
  );

  function GetBoundStopsTrainText(): string {
    return TripHelperV1.getStopsNameAndDuration(railSegment)
      .toString()
      .replace(",", ", ");
  }

  function GetTrainInfo(): string {
    if (railSegment) {
      const uniqueServiceProviders = Array.from(
        new Set(railSegment.map((segment) => segment.serviceProviderName))
      );
      return Array.from(uniqueServiceProviders).toString().replace(",", ", ");
    }
    return "";
  }

  const boundText = GetBoundStopsTrainText();
  const trainInfo = GetTrainInfo();

  const header = (
    <Flex column>
      <Text size="small">
        <Text weight="bold">
          {moment.utc(departureDateTime).format("HH:mm")}
          &nbsp;
          {departure.name}
          &nbsp; - &nbsp;
          {moment.utc(arrivalDateTime).format("HH:mm")}
          &nbsp;
          {arrival.name}
        </Text>
        &nbsp; | &nbsp;
        {`${numOfStops} ${boundText}`}
      </Text>
    </Flex>
  );

  const content = <Text>{trainInfo}</Text>;

  const customInfo = {
    timeAndName: boundText,
    nameAndNumber: trainInfo,
    travelDuration,
    numberOfStops: numOfStops,
  };

  const getRailProductId = (): string => {
    const numberAndDeparture = `${vehicle.number}#${departureDateTime}#${departure.name}`;
    const arrivalAndServiceProvider = `${arrivalDateTime}#${arrival.name}#${railSegment[0].serviceProviderName}`;

    return `${numberAndDeparture}#${arrivalAndServiceProvider}`;
  };

  const productToShare: ProductTrain = {
    productType: "train",
    id: "",
    direction: 1,
    serviceProviderName: "",
    departure: {
      name: departure.name,
      iataCode: departure.iataCode,
      geoCode: {
        latitude: firstSegment.latitude ?? 0,
        longitude: firstSegment.longitude ?? 0,
      },
      countryCode: departure.address.countryCode,
      countryName: departure.address.countryCode,
      cityName: departure.address.cityName,
      address: {
        lines: [],
        cityName: departure.address.cityName,
        postalCode: departure.address.postalCode,
        countryCode: departure.address.countryCode,
      },
      providerCode: departure.providerCode,
      providerStationCode: departure.providerStationCode,
    },
    arrival: {
      name: arrival.name,
      iataCode: arrival.iataCode,
      geoCode: {
        latitude: lastSegment.latitude ?? 0,
        longitude: lastSegment.longitude ?? 0,
      },
      countryCode: arrival.address.countryCode,
      countryName: arrival.address.countryCode,
      cityName: arrival.address.cityName,
      address: {
        lines: [],
        cityName: arrival.address.cityName,
        postalCode: arrival.address.postalCode,
        countryCode: arrival.address.countryCode,
      },
      providerCode: arrival.providerCode,
      providerStationCode: arrival.providerStationCode,
    },
    departureDateTime,
    arrivalDateTime,
    duration: travelDuration,
  };

  useEffect(() => {
    productToShare.id = id.toString();
    dispatch({
      types: types.setTrip,
      payload: productToShare,
    });
    dispatch({
      type: types.addSegment,
      payload: {
        Trip_Product_Train: productToShare,
        customInfo,
        id: getRailProductId(),
      },
    });
    dispatch({
      type: types.setInitialSegments,
      payload: { Trip_Product_Train: productToShare, id: getRailProductId() },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SegmentWrapper
      segment={{ Trip_Product_Train: productToShare, id: getRailProductId() }}
      topDate={range}
      segmentType="train"
    >
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default RailSegmentContentV1;
