/* eslint-disable prefer-destructuring */
import {
  DateFormat,
  DateUtils,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import moment from "moment";
import {
  IArrivalDepartureFlight,
  IArrivalDepartureRail,
  IProductV1,
  ITripV1,
} from "../components/upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import i18n from "../i18n/i18n";
import HostSettings from "../utils/host.settings";
import { diffMinutes } from "./DateHelper";
import { complexTitleNameManager } from "./TripTitleName";

export enum ProductType {
  Hotel = "Trip_Product_Hotel",
  Flight = "Trip_Product_Air",
  Train = "Trip_Product_Train",
  Car = "Trip_Product_Car",
}

export interface FormattedDate {
  start: string;
  end: string;
  range: string;
}

export default class TripHelperV1 {
  /**
   * Checkt if product is of given type.
   *
   * @param productType product type as string
   * @param specifiedType specified type as {@link ProductType}
   * @returns
   */
  public static checkIsTripProduct(
    productType: string,
    specifiedType: ProductType
  ): boolean {
    return productType === specifiedType;
  }

  /**
   * Returns the destination pictures for the trip.
   * If pictures data inside trip object are empty,
   * default pictures are returned.
   *
   * @param trip {@link ITripV1} trip object
   * @returns array of pictures
   */
  public static getDestinationPicture(trip: ITripV1) {
    let destinationPicture;
    if (trip.data.media && trip.data.media.length === 0) {
      const imageSource = HostSettings.getCDNImages;
      destinationPicture = [
        `${imageSource}default/slider.png`,
        `${imageSource}default/square.png`,
        `${imageSource}default/largeRectangle.png`,
      ];
      return destinationPicture;
    }
    return trip.data.media;
  }

  /**
   * Returns the formatted start end and range dates for the trip.
   *
   * @param startDate start date as string
   * @param endDate end date as string
   * @returns object representing dates as {@link FormattedDate}
   */
  public static getStartEndDate(
    startDate: string,
    endDate: string
  ): FormattedDate {
    let rangeString;
    const startDateFormatted = DateUtils.getDateFormat(
      startDate,
      i18n.language,
      DateFormat.DATE_SHORT
    );
    const endDateFormatted = DateUtils.getDateFormat(
      endDate,
      i18n.language,
      DateFormat.DATE_SHORT
    );

    if (startDateFormatted === endDateFormatted) {
      rangeString = startDateFormatted;
    } else {
      rangeString = `${startDateFormatted} - ${endDateFormatted}`;
    }

    return {
      start: startDateFormatted,
      end: endDateFormatted,
      range: rangeString,
    };
  }

  /**
   * Returns the number of nights for the hotel stay.
   *
   * Example:
   * @code 1 night/2 noches
   *
   * @param startDate start date as string
   * @param endDate end date as string
   * @returns localized string representing the number of nights
   */
  public static getHotelNights(startDate: string, endDate: string): string {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    const numberOfNights = Math.ceil(end.diff(start, "days", true));
    const nights =
      numberOfNights > 1
        ? i18n.t("shareTrip.adaptiveCard.hotelNights")
        : i18n.t("shareTrip.adaptiveCard.hotelOneNight");
    return `${numberOfNights} ${nights}`;
  }

  /**
   * Retrurns the travel duration in days, hours, and minutes.
   *
   * @param departure start date as string
   * @param arrival end date as string
   * @returns duration string as `xd yh zmin`
   */
  public static getTravelDuration(departure: string, arrival: string): string {
    const departureTime = new Date(departure);
    const arrivalTime = new Date(arrival);

    const diffInMin = diffMinutes(arrivalTime, departureTime);
    const daysAsNumber = Math.floor(diffInMin / (24 * 60));
    const days =
      daysAsNumber === 0 ? "" : `${Math.floor(diffInMin / (24 * 60))}d `;
    const deltaDiffInMin =
      daysAsNumber > 0 ? diffInMin - 24 * 60 * daysAsNumber : diffInMin;
    const hours =
      Math.floor(deltaDiffInMin / 60) === 0
        ? ""
        : `${Math.floor(deltaDiffInMin / 60)}h `;
    const min = deltaDiffInMin % 60 === 0 ? "" : `${deltaDiffInMin % 60}min `;

    return `${days}${hours}${min}`;
  }

  /**
   * Returns string representing the breakfast inclusion.
   * Logic is based on whether input string contains `not`.
   *
   * @param breakfast string representing breakfast information
   * @returns localized string representing breakfast inclusion
   */
  public static getBreakfastInfo(breakfast: string): string {
    return breakfast.toLowerCase().includes("not")
      ? i18n.t("shareTrip.breakfastNotIncluded")
      : i18n.t("shareTrip.breakfastIncluded");
  }

  /**
   * Returns string representing the number of stops.
   *
   * @param stops number of stops
   * @returns localized string representing number of stops
   */
  public static getNumOfStopsText(stops: number): string {
    if (stops !== 0) {
      return stops > 1
        ? `${stops} ${i18n.t("shareTrip.adaptiveCard.stopPlural")}`
        : `${stops} ${i18n.t("shareTrip.adaptiveCard.stopSingular")}`;
    }
    return i18n.t("shareTrip.adaptiveCard.nonStop");
  }

  /**
   * Returns the stops duration and location for the trip.
   *
   * @param segments array of segments as {@link IProductV1}
   * @returns array of stops and duration strings
   */
  public static getStopsNameAndDuration(segments: IProductV1[]): string[] {
    const stopsAndDuration: string[] = [];

    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < segments.length; index++) {
      const prevSegment = segments[index - 1];
      const currSegment = segments[index];
      let prevArrival;
      let currDeparture;
      let destinationName;
      if (currSegment.productType === ProductType.Flight) {
        const arrival = prevSegment.arrival as IArrivalDepartureFlight;
        const departure = currSegment.departure as IArrivalDepartureFlight;
        prevArrival = arrival.dateTime;
        currDeparture = departure.dateTime;
        destinationName = departure.iataCode;
      } else {
        prevArrival = prevSegment.arrivalDateTime
          ? prevSegment.arrivalDateTime
          : "";
        currDeparture = currSegment.departureDateTime
          ? currSegment.departureDateTime
          : "";
        const departure = currSegment.departure as IArrivalDepartureRail;
        destinationName = departure?.iataCode || departure?.name;
      }
      const { hours, minutes } = this.getStopHoursAndMinutes(
        prevArrival,
        currDeparture
      );
      stopsAndDuration.push(
        this.getStopsAndDurationTexts(hours, minutes, destinationName)
      );
    }

    return stopsAndDuration;
  }

  /**
   * Returns the stops duration.
   *
   * @param prevArrivalTime stop start datetime as string
   * @param newDepartureTime stop end datetime as string
   * @returns object containing hours and minutes range of the stop
   */
  private static getStopHoursAndMinutes(
    prevArrivalTime: string,
    newDepartureTime: string
  ): { hours: number; minutes: number } {
    const diffInMin = diffMinutes(
      new Date(prevArrivalTime),
      new Date(newDepartureTime)
    );
    const hours = Math.floor(diffInMin / 60);
    const minutes = diffInMin % 60;
    return { hours, minutes };
  }

  /**
   * Returns the stops duration and location for the trip.
   *
   * @param hours hours duration of the stop
   * @param min minutes duration of the stop
   * @param stopName stop location name
   * @returns string representing the stop duration and location
   */
  private static getStopsAndDurationTexts(
    hours: number,
    min: number,
    stopName: string
  ): string {
    const finalHours = hours !== 0 ? `${hours}h ` : ``;
    const finalMin = finalHours === `` || min !== 0 ? `${min}min` : ``;

    return ` ${finalHours}${finalMin} ${i18n.t(
      "shareTrip.adaptiveCard.in"
    )} ${stopName}`;
  }

  /**
   * Returns the start and end datetimes of the segment.
   * If data for start or end are missing, returns null.
   *
   * @param segments array of segments as {@link IProductV1}
   * @returns object representing start, end and range dates as {@link FormattedDate}
   */
  public static getSegmentDate(product: IProductV1) {
    let segmentStartDate: string | undefined;
    let segmentEndDate: string | undefined;
    if (product) {
      switch (product.productType) {
        case ProductType.Hotel:
          segmentStartDate = product.checkInDate;
          segmentEndDate = product.checkOutDate;
          break;
        case ProductType.Car:
          segmentStartDate = product.pickup?.localDateTime;
          segmentEndDate = product.dropoff?.localDateTime;
          break;
        default:
          segmentStartDate = product.departureDateTime;
          segmentEndDate = product.arrivalDateTime;
          break;
      }
    }
    if (segmentStartDate && segmentEndDate) {
      return this.getStartEndDate(segmentStartDate, segmentEndDate);
    }
    return null;
  }

  /**
   * Returns information about presence of segment type.
   *
   * @param trip trip object
   * @returns structure containing boolean values for each product type
   */
  public static checkTripProducts(trip: ITripV1 | any) {
    let isAirPresent = false;
    let isRailPresent = false;
    let isHotelPresent = false;
    let isCarPresent = false;
    // when we are populating task module, we have V1 model,
    // when we are populating adaptive card, we have V0 model
    // and here we are checking which model we have
    const products =
      "data" in trip ? trip.data.products : trip.products[0].products;

    products.forEach((product: IProductV1) => {
      if (
        product.productType === ProductType.Flight ||
        ProductType.Flight in product
      ) {
        isAirPresent = true;
      }
      if (
        product.productType === ProductType.Train ||
        ProductType.Train in product
      ) {
        isRailPresent = true;
      }
      if (
        product.productType === ProductType.Hotel ||
        ProductType.Hotel in product
      ) {
        isHotelPresent = true;
      }
      if (
        product.productType === ProductType.Car ||
        ProductType.Car in product
      ) {
        isCarPresent = true;
      }
    });

    return {
      isAirPresent,
      isRailPresent,
      isHotelPresent,
      isCarPresent,
    };
  }

  /**
   * Returns the trip title for the CP flow.
   *
   * If the trip contains only car products string is formatted to one line
   * as localized string - 'Trip by car'.
   *
   * If the trip contains custom title this string is returned.
   *
   * If the trip contains title the returned string is formatted to one line
   * as localized string - example: 'Trip to trip title'.
   *
   * If the trip contains multiple types of products and trip title is empty,
   * it returns empty string.
   *
   * Note: This function reuses the logic from {@link complexTitleNameManager},
   * in effort to keep the logic consistent throughout the application.
   *
   * @param trip trip object
   * @param clientType client type as string
   * @returns trip title
   */
  public static getTripTitleForCPFlow(
    trip: ITripV1 | any,
    clientType: string
  ): string {
    let formattedTitle: string;
    if ("data" in trip) {
      formattedTitle = complexTitleNameManager(i18n.t, trip.data, false);
    } else {
      formattedTitle = complexTitleNameManager(i18n.t, trip, false);
    }
    return this.formatTitleToOneLine(formattedTitle, clientType);
  }

  /**
   * Checks if title is too long, is so, returns shortened version,
   * otherwise returns original title.
   *
   * @param title trip title
   * @returns trip title formatted to one line
   */
  public static formatTitleToOneLine(
    title: string,
    clientType: string
  ): string {
    // for the web chat, char in one line are 29
    // for desktop, char in one line are 60
    const charactersPerLine =
      clientType === "desktop" || clientType === "web" ? 60 : 29;
    return title.length > charactersPerLine
      ? `${title.substring(0, charactersPerLine - 3)}...`
      : title;
  }
}
