import { downloadCsv } from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-events/dist/components/event-share-trip/trip-body/utils/csvAttendeesExport";
import {
  ArrowDownload20Regular,
  ContactCard20Regular,
} from "@fluentui/react-icons";
import {
  Button,
  ComponentStyleFunctionParam,
  Flex,
  FormDropdown,
  Table,
} from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckFeatureToggle from "../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../utils/constants";
import { getDevice } from "../../utils/device.utils";
import Styles from "./EventAttendees.module.scss";
import {
  dontClose,
  getDropdownClasses,
  getDropdownItems,
  getRows,
  getSearchPlaceholder,
  handleClear,
  handleOnChange,
  handleSearchQueryChange,
} from "./EventAttendeesUtils";

function EventAttendees(data: any) {
  const urlInfo = useCallback(
    () =>
      JSON.parse(
        decodeURIComponent(
          // eslint-disable-next-line react/destructuring-assignment
          data?.location?.search?.replace("?attendeesInfo=", "")
        )
      ),
    [data]
  );
  const { t } = useTranslation();
  const isMobile = ["mobile", "tablet"].includes(getDevice());
  const [query, setQuery] = useState("");
  const [updatedAttendeesObject, setUpdatedAttendeesObject] = useState(
    urlInfo().contactsObject
  );
  const [isMobileTyping, setIsMobileTyping] = useState(false);
  const initialAttendeesObject = useRef(urlInfo().contactsObject);
  const featureDownloadAttendeesActivated = CheckFeatureToggle(
    FeatureToggleDefinition.eventAttendeesDownload.id
  );
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });
  const ICON_STYLE_DISABLED = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.colorScheme?.default?.foregroundDisabled
      : "",
  });
  const searchBarText = t("events.eventAttendees.searchBar");

  useEffect(() => {
    const handleTypingStart = () => setIsMobileTyping(true && isMobile);
    const handleTypingEnd = () => setIsMobileTyping(false);

    const inputElement = document.getElementById("downshift-0-input");
    if (inputElement) {
      inputElement.addEventListener("focus", handleTypingStart);
      inputElement.addEventListener("blur", handleTypingEnd);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", handleTypingStart);
        inputElement.removeEventListener("blur", handleTypingEnd);
      }
    };
  }, [isMobile]);

  return (
    <Flex
      column
      data-testid="event-attendees-taskmodule"
      className={Styles.taskmoduleContent}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables.colorScheme?.default.background,
        height: "100%",
        flexDirection: "column",
      })}
    >
      <FormDropdown
        label={
          <label
            htmlFor="trip-logistic-event-attendees-dropdown"
            className={Styles.srOnly}
          >
            {searchBarText}
          </label>
        }
        aria-label={searchBarText}
        role="searchbox"
        aria-haspopup="listbox"
        noResultsMessage={t("searchContacts.noResultsMessage")}
        items={getDropdownItems(initialAttendeesObject.current, query)}
        search={(items) => items}
        fluid
        clearable
        className={getDropdownClasses(isMobile)}
        styles={{
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
        data-testid="trip-logistic-event-attendees-dropdown"
        onChange={(e, param) =>
          handleOnChange(
            e,
            param,
            setQuery,
            setUpdatedAttendeesObject,
            initialAttendeesObject
          )
        }
        onSearchQueryChange={(e, param) =>
          handleSearchQueryChange(e, param, setQuery, () =>
            handleClear(
              setQuery,
              setUpdatedAttendeesObject,
              initialAttendeesObject
            )
          )
        }
        toggleIndicator=""
        onKeyDown={dontClose}
        searchInput={getSearchPlaceholder(query, searchBarText)}
      />
      <Flex
        column
        tabIndex={0}
        styles={{
          overflow: "auto",
          height: "100%",
        }}
      >
        <Table
          className={`${Styles.tableStyle} ${Styles.table}`}
          styles={{
            width: "100%",
            minWidth: "520px",
            height: "auto",
            tableLayout: "fixed",
          }}
          header={{
            items: [
              {
                content: (
                  <Flex
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    aria-label={t("events.eventAttendees.name")}
                  >
                    <ContactCard20Regular style={{ marginRight: "8px" }} />
                    {t("events.eventAttendees.name")}
                  </Flex>
                ),
                key: "name-header",
                role: "columnheader",
                className: Styles.firstCol,
              },
              {
                content: t("events.eventAttendees.tripDates"),
                key: "trip-dates-header",
                role: "columnheader",
                "aria-label": t("events.eventAttendees.tripDates"),
                className: Styles.secondCol,
              },
              {
                content: t("events.eventAttendees.sharedBookings"),
                key: "bookings-header",
                role: "columnheader",
                "aria-label": t("events.eventAttendees.sharedBookings"),
                className: Styles.thirdCol,
              },
            ],
            className: `${Styles.tableColumns} ${Styles.tableHeader}`,
            role: "row",
          }}
          rows={getRows(
            updatedAttendeesObject,
            ICON_STYLE,
            ICON_STYLE_DISABLED,
            t,
            urlInfo().user
          )}
        />
      </Flex>
      {!isMobileTyping && (
        <Flex
          hAlign="end"
          className={Styles.buttons}
          styles={(theme) => ({
            borderTop: isMobile ? "none" : "1px solid",
            borderColor:
              theme.theme?.siteVariables?.colorScheme?.default?.border2,
            marginTop: "auto",
            height: "91px",
          })}
        >
          {featureDownloadAttendeesActivated &&
            !isMobile &&
            urlInfo().enhancedInfo && (
              <Button
                content={t("events.eventAttendees.downloadAttendeesList")}
                icon={<ArrowDownload20Regular />}
                iconPosition="before"
                styles={(theme) => ({
                  height: "32px",
                  marginTop: "23px",
                  marginBottom: "32px",
                  position: "fixed",
                  left: "32px",
                  color:
                    theme.theme.siteVariables.colorScheme?.default.foreground,
                })}
                flat
                text
                data-testid="trip-logistic-event-attendees-download-list"
                onClick={() =>
                  downloadCsv(urlInfo().enhancedInfo, urlInfo().eventId)
                }
              />
            )}
          <Button
            content={t("closeCollaborators.editor.closeButton")}
            flat
            onClick={() => dialog.url.submit("close")}
            data-testid="close-collaborators-dialog-cancel-button"
            tinted={isMobile}
            styles={() => ({
              marginTop: "24px",
              marginBottom: "32px",
            })}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default EventAttendees;
