import { Dialog } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import DialogHeader from "../../../common/components/dialog-header/DialogHeader";
import { useAppSelector } from "../../../store/StoreHooks";
import { IDialogProps } from "./ExpenseDetailsDialog.model";
import Styles from "./ExpenseDetailsDialog.module.scss";

function ExpenseDetailsDialog({ dialogInfo, dispatchDialog }: IDialogProps) {
  const {
    headerTitle,
    isVisible,
    cancelButtonText,
    dialogBodyComponent,
    confirmButtonText,
    tryAgain,
  } = dialogInfo;
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.popup",
  });

  const isError = useAppSelector(
    (state) => state.expenseStatementsToSubmit.isError
  );

  const cancelHandler = () => dispatchDialog({ type: "close" });
  const confirmBtnText = isError ? t("tryAgain") : confirmButtonText;

  return (
    <Dialog
      data-testid="expense__expense-statments-to-submit--dialog"
      closeOnOutsideClick
      onCancel={cancelHandler}
      cancelButton={cancelButtonText}
      onConfirm={tryAgain}
      confirmButton={confirmBtnText}
      header={
        <DialogHeader
          dispatchDialog={dispatchDialog}
          headerTitle={headerTitle}
        />
      }
      content={dialogBodyComponent}
      className={Styles["expense-details-dialog"]}
      styles={(theme) => ({
        background: theme.theme.siteVariables.colorScheme?.default.background,
      })}
      backdrop
      open={isVisible}
    />
  );
}
export default ExpenseDetailsDialog;
